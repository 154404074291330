import type { Meta } from '@/@types/meta'
import type { FC } from 'react'
import { Seo } from '../Seo'

export const LayoutOnboarding: FC<{ meta?: Meta }> = ({
  meta,
  children,
  ...props
}) => {
  return (
    <>
      <Seo meta={meta} />
      <div
        className="font-primary flex min-h-screen flex-col items-center overflow-x-hidden bg-white py-8 px-6 text-sm text-slate-800 lg:px-8"
        {...props}
      >
        <header className="m-4 flex flex-col sm:mx-auto sm:w-full sm:max-w-md md:m-6 lg:m-8">
          <div className="z-100 mx-auto text-blue-900">
            <svg
              className="fill-current"
              width="88"
              height="24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>teero</title>
              <path d="M6.183 7.913v10.494c0 .713.166 1.222.499 1.533.33.31.9.464 1.701.464h2.405v3.268H7.697c-1.764 0-3.117-.41-4.054-1.239-.939-.824-1.41-2.168-1.41-4.026V7.913H0V4.715h2.233V0h3.952v4.715h4.603v3.198H6.183ZM77.92 3.816c-5.556 0-10.077 4.527-10.077 10.09 0 2.43.913 4.798 2.571 6.668l.12.133.119-.133a9.739 9.739 0 0 1 2.814-2.187l.188-.098-.147-.153a6.099 6.099 0 0 1-1.71-4.23c0-3.381 2.748-6.13 6.123-6.13 3.377 0 6.125 2.75 6.125 6.13a6.1 6.1 0 0 1-1.711 4.23l-.147.153.188.098a9.739 9.739 0 0 1 2.814 2.187l.12.133.118-.133C87.088 18.704 88 16.337 88 13.907c-.002-5.564-4.523-10.09-10.08-10.09Z" />
              <path d="M77.92 20.583c-1.76 0-3.41.71-4.642 2.001l-.147.153.188.098c1.488.773 3.037 1.165 4.6 1.165 1.565 0 3.114-.392 4.602-1.165l.188-.098-.147-.153a6.362 6.362 0 0 0-4.642-2.001ZM62.994 4.896c.95-.538 2.078-.809 3.385-.809V8.15h-.996c-1.535 0-2.698.387-3.486 1.169-.79.78-1.185 2.13-1.185 4.06v9.976h-3.917V4.399h3.917V7.15a6.134 6.134 0 0 1 2.282-2.254ZM43.737 20.361c-1.467 0-2.703-.46-3.712-1.376-1.009-.917-1.57-2.13-1.683-3.646h14.465c.091-.574.136-1.216.136-1.927 0-1.833-.389-3.456-1.167-4.868a8.252 8.252 0 0 0-3.264-3.286c-1.396-.78-2.988-1.17-4.775-1.17-1.856 0-3.5.402-4.932 1.207-1.431.803-2.542 1.943-3.332 3.423-.79 1.478-1.185 3.194-1.185 5.142 0 1.95.407 3.664 1.22 5.144.814 1.479 1.934 2.624 3.368 3.44 1.431.816 3.052 1.223 4.861 1.223 2.221 0 4.081-.556 5.583-1.67a8.543 8.543 0 0 0 2.783-3.494h-4.424c-.895 1.214-2.194 1.858-3.942 1.858ZM40.096 8.68c.962-.86 2.12-1.289 3.47-1.289 1.489 0 2.737.437 3.746 1.308 1.009.872 1.523 2.03 1.546 3.474H38.377c.184-1.466.757-2.632 1.719-3.493ZM22.561 20.361c-1.466 0-2.702-.46-3.711-1.376-1.01-.917-1.57-2.13-1.684-3.646h14.465c.092-.574.137-1.216.137-1.927 0-1.833-.39-3.456-1.168-4.868a8.252 8.252 0 0 0-3.264-3.286c-1.396-.78-2.987-1.17-4.775-1.17-1.855 0-3.5.402-4.931 1.207-1.432.803-2.543 1.943-3.333 3.423-.79 1.478-1.185 3.194-1.185 5.142 0 1.95.407 3.664 1.22 5.144.814 1.479 1.934 2.624 3.368 3.44 1.431.816 3.052 1.223 4.861 1.223 2.222 0 4.081-.556 5.583-1.67a8.542 8.542 0 0 0 2.783-3.494h-4.424c-.897 1.214-2.194 1.858-3.942 1.858ZM18.918 8.68c.962-.86 2.12-1.289 3.471-1.289 1.488 0 2.736.437 3.745 1.308 1.009.872 1.523 2.03 1.547 3.474H17.2c.182-1.466.757-2.632 1.717-3.493Z" />
            </svg>
          </div>
        </header>

        <div className="container w-full sm:max-w-[360px] ">{children}</div>
      </div>
    </>
  )
}
