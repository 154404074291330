import { classNames } from '@/utils/classNames'
import { Component, FC } from 'react'
import { ButtonSpinner } from '../spinners'

interface FormButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean
  loading?: boolean
  children: React.ReactNode
  variant?: 'primary' | 'ghost'
}

export const FormButton: FC<FormButtonProps> = ({
  disabled,
  loading,
  children,
  variant = 'ghost',
  ...props
}) => {
  return (
    <button
      {...props}
      className={classNames(
        'flex w-full justify-center rounded-md p-3 text-sm font-medium focus:ring-2  focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
        variant == 'primary'
          ? 'bg-blue-900 text-white hover:bg-blue-700 focus:ring-blue-800'
          : 'bg-slate-100 hover:bg-slate-200',
      )}
      disabled={disabled}
    >
      {loading && (
        <ButtonSpinner className="-ml-1 mr-3 h-5 w-5 animate-spin text-blue-900" />
      )}
      {children}
    </button>
  )
}
