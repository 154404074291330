import { FC } from 'react'

export const TextCenteredLine: FC = ({ children, ...props }) => {
  return (
    <div className="relative" {...props}>
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-slate-200"></div>
      </div>
      <div className="relative flex justify-center">
        <span className="mx-4 flex bg-white text-center">{children}</span>
      </div>
    </div>
  )
}
