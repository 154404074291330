import { FC, ReactNode } from 'react'

interface MessageBoxProps {
  type: 'error'
  title: string
  message: ReactNode
  icon: ReactNode
}

export const MessageBox: FC<MessageBoxProps> = ({
  type = 'error',
  title,
  message,
  icon,
  ...props
}) => {
  return (
    <div className="my-4 rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">{icon}</div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">{title}</h3>
          <div className="mt-2 text-sm text-red-700">{message}</div>
        </div>
      </div>
    </div>
  )
}
