import { FC, useState } from 'react'

interface FormInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string
  label: string
}

export const FormInput: FC<FormInputProps> = ({
  id,
  label,
  type,
  ...props
}) => {
  const [viewPassword, setViewPassword] = useState(false)
  return (
    <div>
      <label htmlFor={id} className="block text-sm font-medium text-slate-800">
        {label}
      </label>
      <div className="relative mt-2">
        {type === 'password' && (
          <svg
            onClick={() => setViewPassword(!viewPassword)}
            className="absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>{viewPassword ? 'hide' : 'unhide'}</title>
            <path
              d="M14.122 9.88C15.293 11.051 15.293 12.952 14.122 14.125C12.951 15.296 11.05 15.296 9.87703 14.125C8.70603 12.954 8.70603 11.053 9.87703 9.88C11.05 8.707 12.95 8.707 14.122 9.88"
              stroke="#94A3B8"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3 12C3 11.341 3.152 10.689 3.446 10.088V10.088C4.961 6.991 8.309 5 12 5C15.691 5 19.039 6.991 20.554 10.088V10.088C20.848 10.689 21 11.341 21 12C21 12.659 20.848 13.311 20.554 13.912V13.912C19.039 17.009 15.691 19 12 19C8.309 19 4.961 17.009 3.446 13.912V13.912C3.152 13.311 3 12.659 3 12Z"
              stroke="#94A3B8"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            {viewPassword && (
              <path
                d="M5.41596 7.14419C2.37051 4.40035 2.51311 4.23108 5.73439 6.7662C8.95552 9.3015 14.0421 13.5866 17.0928 16.3348C20.1383 19.0787 19.9957 19.2479 16.7744 16.7128C13.5478 14.1733 8.46394 9.89046 5.41596 7.14419Z"
                stroke="#94A3B8"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            )}
          </svg>
        )}
        <input
          className="block w-full appearance-none rounded-lg border border-slate-100 p-3 placeholder-slate-400 focus:border-blue-800 focus:outline-none focus:ring-blue-800"
          {...props}
          id={id}
          name={id}
          type={type === 'password' && viewPassword ? 'text' : type}
        />
      </div>
    </div>
  )
}
